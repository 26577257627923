import React from 'react'

export const Logo = () => {
    return (
        <div class="logo-container">
        <a href="#home">
        <img src= "https://datadashboard.health.gov.il/COVID-19/assets/images/logo_DAshboard-01.png" />
        </a>
          </div>
         
    )
}

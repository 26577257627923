import React from 'react'

export const HamburgerButton = () => {
     // onClick=
    // {
        // (e)=>
        // makeMenuAppear(e,"dropdowns-menu-id-small-screen","transition-height" ) 
    // }
    return (
        <div id="hamburger-butt" 
        >
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          </div>
    )
}
